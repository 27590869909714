<template>
  <a-drawer width="800" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>批量修改图片</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="图片组" prop="picPathStr">
        <a-upload
          action="https://pc.tlxone.com/admin-api/common/local/file/upload"
          :headers="header"
          accept=".png,.jpg,.jpeg"
          list-type="picture-card"
          :file-list="fileList"
          :multiple="true"
          @preview="handlePreview"
          @change="handleChange"
        >
          <a-icon type="plus" />
          <div class="ant-upload-text">
            Upload
          </div>
        </a-upload>
      </a-form-model-item>
      <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewImage" />
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import store from '@/store'
import { batchEditTemplateDay } from '@/api/valve/template'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data() {
    return {
      header: { Authorization: 'Bearer ' + store.getters.token },
      open: false,
      submitLoading: false,
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 表单参数
      form: {
        templateId: 0,
        dayList: []
      },
      rules: {
        dayList: [
          { required: true, message: '消息图片长不能为空', trigger: 'blur' }
        ]
      },
      projectList: []
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    handlePreview(file) {
      this.previewImage = file.response.data || file.thumbUrl
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      // console.log(fileList)
      this.fileList = fileList
    },
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        templateId: 0,
        dayList: []
      }
    },
    /** 新增按钮操作 */
    handleAdd(id) {
      this.open = true

      this.form = {
        templateId: id,
        dayList: []
      }
    },
    /** 提交按钮 */
    submitForm: function () {
      console.log(this.fileList.length)
      this.fileList.map(item => {
        this.form.dayList.push({
          fileName: item.name,
          picPath: item.response.data,
          remark: ''
        })
      })
      console.log(this.form)
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          batchEditTemplateDay(this.form).then(response => {
            this.$message.success(
              '修改成功',
              3
            )
            this.open = false
            this.$emit('ok')
          }).finally(() => {
            this.submitLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .ant-upload-preview {
    position: relative;
    width: 100px;
    height: 100px;
    .upload-icon {
      position: absolute;
      top: 0;
      right: 10px;
      font-size: 1.4rem;
      padding: 0.5rem;
      background: rgba(222, 221, 221, 0.7);
      border: 1px solid rgba(0, 0, 0, 0.2);
    }
    .mask {
      position: absolute;
      background: rgba(0,0,0,0.4);
      cursor: pointer;
      transition: opacity 0.4s;
      &:hover {
        opacity: 1;
      }
      i {
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -1rem;
        margin-top: -1rem;
        color: #d6d6d6;
      }
    }
    img, .mask {
      width: 100%;
      max-width: 180px;
      height: 100%;
      overflow: hidden;
      object-fit: contain;
    }
  }
</style>
